"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var operationRouter = {
  path: '/operation',
  component: _layout.default,
  redirect: '/operation/setting',
  name: 'Operation',
  meta: {
    title: '设置',
    icon: 'clipboard',
    roles: ['admin']
  },
  children: [{
    path: 'modifyStoreInfo',
    name: 'modifyStoreInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/modifyStoreInfo'));
      });
    },
    meta: {
      title: '商户基本设置',
      icon: 'clipboard'
    }
  }, {
    path: 'roleManager',
    name: 'RoleManager',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/administratorAuthority'));
      });
    },
    meta: {
      title: '管理权限',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'identityManager',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/administratorAuthority/identityManager'));
        });
      },
      name: 'identityManager',
      meta: {
        title: '角色管理',
        icon: ''
      }
    }, {
      path: 'adminList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/administratorAuthority/adminList'));
        });
      },
      name: 'adminList',
      meta: {
        title: '管理员列表',
        icon: ''
      }
    }]
  }, {
    path: 'export',
    name: 'systemExport',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/systemExport'));
      });
    },
    meta: {
      title: '数据导出',
      icon: 'clipboard'
    },
    hidden: false
  }, {
    path: 'freightSet',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/deliverGoods/freightSet'));
      });
    },
    name: 'freightSet',
    meta: {
      title: '运费模板',
      noCache: true
    }
  }, {
    path: 'pickUpPoint',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/deliverGoods/pickUpPoint'));
      });
    },
    name: 'pickUpPoint',
    meta: {
      title: '提货点',
      noCache: true
    }
  }]
};
var _default = exports.default = operationRouter; //collate