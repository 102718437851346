"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var logistics = _interopRequireWildcard(require("@/api/logistics.js"));
var _orderDetail = _interopRequireDefault(require("@/components/orderDetail"));
var _orderSend = _interopRequireDefault(require("./orderSend"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _utils = require("@/utils");
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'orderlistDetails',
  components: {
    detailsFrom: _orderDetail.default,
    orderSend: _orderSend.default
  },
  data: function data() {
    return {
      addressDetailList: [],
      RefuseVisible: false,
      RefuseData: {},
      orderNo: '',
      refundVisible: false,
      refundData: {},
      dialogVisibleJI: false,
      tableDataLog: {
        data: [],
        total: 0
      },
      tableFromLog: {
        page: 1,
        limit: 10,
        orderNo: 0
      },
      LogLoading: false,
      isCreate: 1,
      editData: null,
      dialogVisible: false,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        status: 'all',
        dateLimit: '',
        orderNo: '',
        addressDetail: '',
        page: 1,
        shippingTypeFilter: '',
        limit: 20,
        type: ''
      },
      orderChartType: {},
      timeVal: [],
      fromList: this.$constants.fromList,
      fromType: [{
        value: '',
        text: this.$t('order.index.5u3ctbxexww0')
      }, {
        value: '0',
        text: this.$t('order.index.5u3ctbxez0w0')
      },
      // { value: '1', text: '视频号' },
      {
        value: '2',
        text: this.$t('order.index.5u3ctbxez280')
      }],
      selectionList: [],
      ids: '',
      orderids: '',
      cardLists: [],
      isWriteOff: (0, _utils.isWriteOff)(),
      proType: 0,
      active: false,
      card_select_show: false,
      checkAll: false,
      checkedCities: [this.$t('order.index.5u3ctbxeyg80'), this.$t('order.index.5u3ctbxez3k0'), this.$t('order.index.5u3ctbxeyk00'), this.$t('order.index.5u3ctbxeyls0'), this.$t('order.index.5u3ctbxeyn80'), this.$t('order.index.5u3ctbxeyp40'), this.$t('order.index.5u3ctbxeyss0')],
      columnData: [this.$t('order.index.5u3ctbxeyg80'), this.$t('order.index.5u3ctbxez3k0'), this.$t('order.index.5u3ctbxeyk00'), this.$t('order.index.5u3ctbxeyls0'), this.$t('order.index.5u3ctbxeyn80'), this.$t('order.index.5u3ctbxeyp40'), this.$t('order.index.5u3ctbxeyss0')],
      isIndeterminate: true,
      orderDatalist: null
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:order:page:list'])) this.getList();
    if ((0, _permission.checkPermi)(['merchant:order:status:num'])) this.getOrderStatusNum();
    if ((0, _permission.checkPermi)(['merchant:order:page:list'])) this.getDataList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    addressDetailChange: function addressDetailChange(e) {
      this.getList();
    },
    // 数据列表
    getDataList: function getDataList() {
      var _this = this;
      this.loading = true;
      logistics.upPointTemplatesList({
        status: 0,
        limit: 9999
      }).then(function (res) {
        _this.addressDetailList = res.list;
      });
    },
    // 核销订单
    onWriteOff: function onWriteOff(row) {
      var _this2 = this;
      this.$modalPrompt('text', this.$t('order.index.5u3ctbxez500')).then(function (V) {
        (0, _order.writeUpdateApi)({
          verifyCode: V
        }).then(function () {
          _this2.$message.success(_this2.$t('order.index.5u3ctbxez6c0'));
          _this2.seachList();
        });
      });
    },
    resetFormRefundhandler: function resetFormRefundhandler() {
      this.refundVisible = false;
    },
    resetFormRefusehand: function resetFormRefusehand() {
      this.RefuseVisible = false;
    },
    resetForm: function resetForm(formValue) {
      this.dialogVisible = false;
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
    },
    // 发送
    sendOrder: function sendOrder(row) {
      var _this3 = this;
      if (row.isLogoff) {
        this.$modalSure(this.$t('order.index.5u3ctbxez7k0')).then(function () {
          _this3.onSend(row);
        });
      } else {
        this.onSend(row);
      }
    },
    //发货操作
    onSend: function onSend(row) {
      this.orderNo = row.orderNo;
      this.$refs.send.modals = true;
      this.$refs.send.getList();
      this.$refs.send.orderProDetail(row.orderNo);
    },
    // 订单删除
    handleDelete: function handleDelete(row, idx) {
      var _this4 = this;
      if (row.isDel) {
        this.$modalSure().then(function () {
          (0, _order.orderDeleteApi)({
            orderNo: row.orderNo
          }).then(function () {
            _this4.$message.success(_this4.$t('order.index.5u3ctbxez9k0'));
            _this4.tableData.data.splice(idx, 1);
          });
        });
      } else {
        this.$confirm(this.$t('order.index.5u3ctbxezas0'), this.$t('order.index.5u3ctbxezbw0'), {
          confirmButtonText: this.$t('order.index.5u3ctbxezd80'),
          type: 'error'
        });
      }
    },
    // 详情
    onOrderDetails: function onOrderDetails(id) {
      this.orderNo = id;
      this.$refs.orderDetail.getDetail(id);
      this.$refs.orderDetail.getOrderInvoiceList(id);
      this.$refs.orderDetail.dialogVisible = true;
    },
    getDetail: function getDetail(id) {
      var _this5 = this;
      this.loading = true;
      (0, _order.orderDetailApi)(id).then(function (res) {
        _this5.orderDatalist = res;
        _this5.loading = false;
      }).catch(function () {
        _this5.orderDatalist = null;
        _this5.loading = false;
      });
    },
    // 订单记录
    onOrderLog: function onOrderLog(id) {
      var _this6 = this;
      this.dialogVisibleJI = true;
      this.LogLoading = true;
      this.tableFromLog.orderNo = id;
      (0, _order.orderLogApi)(this.tableFromLog).then(function (res) {
        _this6.tableDataLog.data = res.list;
        _this6.tableDataLog.total = res.total;
        _this6.LogLoading = false;
      }).catch(function () {
        _this6.LogLoading = false;
      });
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.onOrderLog();
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.onOrderLog();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 备注
    onOrderMark: function onOrderMark(row) {
      var _this7 = this;
      this.$modalPrompt('textarea', this.$t('order.index.5u3ctbxezec0'), row.merRemark).then(function (V) {
        (0, _order.orderMarkApi)({
          remark: V,
          orderNo: row.orderNo
        }).then(function () {
          _this7.$message.success(_this7.$t('order.index.5u3ctbxezg00'));
          _this7.getList();
        });
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectionList = val;
      var data = [];
      this.selectionList.map(function (item) {
        data.push(item.orderNo);
      });
      this.ids = data.join(',');
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
    },
    // 列表
    getList: function getList(num) {
      var _this8 = this;
      this.listLoading = true;
      if (num) {
        this.tableFrom.page = num;
      }
      (0, _order.orderListApi)(this.tableFrom).then(function (res) {
        _this8.tableData.data = res.list || [];
        _this8.tableData.total = res.total;
        _this8.listLoading = false;
        _this8.checkedCities = _this8.$cache.local.has('order_stroge') ? _this8.$cache.local.getJSON('order_stroge') : _this8.checkedCities;
      }).catch(function () {
        _this8.listLoading = false;
      });
    },
    // 获取各状态数量
    getOrderStatusNum: function getOrderStatusNum() {
      var _this9 = this;
      (0, _order.orderStatusNumApi)({
        dateLimit: this.tableFrom.dateLimit,
        type: this.tableFrom.type
      }).then(function (res) {
        _this9.orderChartType = res;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    exports: function exports() {
      var loading = this.$loading({
        lock: true,
        text: '导出中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var data = {
        dateLimit: this.tableFrom.dateLimit,
        orderNo: this.tableFrom.orderNo,
        status: this.tableFrom.status,
        type: this.tableFrom.type,
        shippingTypeFilter: this.tableFrom.shippingTypeFilter
      };
      (0, _order.orderExcelApi)(data).then(function (res) {
        var tempPage = window.open('', '_blank');
        tempPage.location = res.fileName;
        loading.close();
      });
    },
    renderHeader: function renderHeader(h) {
      var _this10 = this;
      return h("p", [h("span", {
        "style": "padding-right:5px;"
      }, [this.$t('order.index.5u3ctbxeytw0')]), h("i", {
        "class": "el-icon-setting",
        "on": {
          "click": function click() {
            return _this10.handleAddItem();
          }
        }
      })]);
    },
    handleAddItem: function handleAddItem() {
      if (this.card_select_show) {
        this.$set(this, 'card_select_show', false);
      } else if (!this.card_select_show) {
        this.$set(this, 'card_select_show', true);
      }
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedCities = val ? this.columnData : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.columnData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.columnData.length;
    },
    checkSave: function checkSave() {
      this.$set(this, 'card_select_show', false);
      this.$modal.loading(this.$t('order.index.5u3ctbxezh40'));
      this.$cache.local.setJSON('order_stroge', this.checkedCities);
      setTimeout(this.$modal.closeLoading(), 1000);
    },
    //打印小票
    onOrderPrint: function onOrderPrint(data) {
      var _this11 = this;
      (0, _order.orderPrint)(data.orderNo).then(function (res) {
        _this11.$modal.msgSuccess(_this11.$t('order.index.5u3ctbxezj00'));
      }).catch(function (error) {
        _this11.$modal.msgError(error.message);
      });
    }
  }
};